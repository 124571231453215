import React, { useState } from "react"
import axios from "axios"

function SendgridSignupForm() {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const data = {
    email,
    list_id: "a7c1003b-a6de-4a3a-8044-95fc949c201f", // In-blog-post form contacts list
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(
        "https://backend.thesis.co/mailing-list/signup",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )

      if (response.status === 200) {
        if (response.data?.newSubscriber !== false) {
          setMessage("Thanks for subscribing!")
        } else {
          setMessage("Looks like you're already subscribed!")
        }
      } else {
        setMessage("Oops, it didn't work!")
      }
    } catch (error) {
      setMessage("Oops, it didn't work!")
    }
  }

  return (
    <div>
      <h3>Sign up for the Thesis* newsletter</h3>
      <p>
        You&apos;ll receive blog posts about company building, crypto, and more
        in your inbox, about twice per month.
      </p>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">
          Enter your email:
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ padding: "1em" }}
          />
        </label>
        <button
          type="submit"
          style={{ background: "black", color: "white", padding: "1em" }}
        >
          Subscribe
        </button>
        {message && <p style={{ padding: "1em 0" }}>*{message}</p>}
      </form>
    </div>
  )
}

export default SendgridSignupForm
